import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { colorUtils } from '@utils/Color';
import { AvatarData } from '@dto/commons/avatar';
import { NgIf } from '@angular/common';
import { IconDirective } from '../icon/icon.directive';
import { COLORS } from '@crm/pages/dashboard/widgets/colors';

@Component({
  selector: 'avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    IconDirective
  ],
  standalone: true
})
export class AvatarComponent {
  color: string = '#ffffff00';
  textColor: string = '#ffffffcc';
  letter: string = '?';

  hasPhoto = false;
  isIcon = false;

  photo?: string;
  style = {
    backgroundColor: this.color,
    color: this.textColor,
    backgroundImage: this.photo,
    fontSize: '1rem',
    width: '2.5rem',
    height: '2.5rem',
    lineHeight: '2.5rem',
    borderRadius: '50%',
    backgroundSize: 'cover'
  };

  @Input() set backgroundSize(s: 'contain' | 'cover') {
    this.style.backgroundSize = s;
  }

  @Input() set borderRadius(r: string) {
    this.style.borderRadius = r;
  }

  @Input() set iconColor(c: string) {
    this.style.color = this.textColor = c;
  }

  @Input() set size(rem: number) {
    this.style = {
      ...this.style,
      height: rem + 'rem',
      width: rem + 'rem',
      lineHeight: rem + 'rem',
      fontSize: (rem / 2.2).toFixed(2) + 'rem',
    };
  }

  @Input() set data(p: AvatarData | any) {
    const photo = (p.avatar || p.logo || p.photoUrl || p.icon)?.trim();
    this.hasPhoto = (photo?.length ?? 0) >= 3;

    if (this.hasPhoto) {
      this.isIcon = this.hasPhoto && !photo?.includes(".");
      this.photo = this.isIcon ? photo : 'url(' + photo + ')';
      if (this.isIcon) {
        this.textColor = COLORS.TEXT;
        this.color = COLORS.TRANSPARENT;
      }
    }

    else if (p.id) {
      const text = (p.display || p.name || p.fullName || p.lastName || p.firstName || p.email || '?')
        .trim().toUpperCase();
      this.letter = text === '' ? '?' : text.charAt(0);
      const c1 = colorUtils.generateHSL(p.id);
      this.color = c1.toString();
      const c2 = c1.rotate(c1.h > 180 ? 15 : - 15).lighten(.15).toString();
      this.photo = `linear-gradient(0deg, ${c1} 0%, ${c2} 90%, ${c2} 100%)`;
      this.textColor = c1.saturate(.6).lighten(.9).toString();
    }

    this.style = {...this.style, backgroundColor: this.color, color: this.textColor, backgroundImage: this.photo };
  };
}
