export const COLORS = {
  TEXT: "#525252",
  GREEN: "#00d093",
  BLUE: "#397fff",
  RED: "#f36b6b",
  YELLOW: "#FFC100",
  WHITE: "#FFFFFF",
  MARINE: "#3BBDC4",
  GRAY: "#bcc5d0",
  DARK_BLUE: "#1155d1",
  DARK_GRAY: "#82878e",
  LIGHT_BLUE: "#88b2ff",
  LIGHT_MARINE: "#b1e5e7",
  LIGHT_GRAY: "#E7E9EC",
  TRANSPARENT: "#FFFFFF00",
  PEN: "#1d68c5",
  SIGNATURE: "#124C94",

  DARK_BLUE_RGB: "17, 85, 209",
  BLUE_RGB: "57, 127, 255",
  LIGHT_BLUE_RGB: "136, 178, 255",
  GREEN_RGB: "0, 208, 147",
  YELLOW_RGB: "255, 193, 0",
  MAGENTA_RGB: "255, 63, 165",
  RED_RGB: "243, 107, 107",
  MARINE_RGB: "59, 189, 196",
  LIGHT_MARINE_RGB: "155, 209, 237",
  DARK_MARINE_RGB: "59, 187, 194",

  TOOLTIP_BG: "rgba(19, 45, 85, 0.9)",

  BACK_COLOR_TOP: '#fafbff',
};
