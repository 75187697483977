export class HSL {
  constructor(public h: number, public s: number, public l: number) {
  }

  /**
   * @param value -- coefficient between -1 and 1
   */
  modifyBrightness(value: number): HSL {
    let newLightness = this.l + 10 * value;
    newLightness = value > 0 ?
      newLightness * (1 - value) + 100 * value :
      newLightness * (1 + value);

    if (newLightness > 100) {
      newLightness = 100;
    }
    if (newLightness < 0) {
      newLightness = 0;
    }
    return new HSL(this.h, this.s, newLightness);
  }

  toString(s?: number | null, l?: number | null, opacity = 1): string {
    return `hsla(${this.h} ${s ?? this.s}% ${l ?? this.l}% / ${opacity})`;
  }

  rotate(deg: number): HSL {
    let newH = this.h + deg;
    if (newH < 0) {
      newH += 360;
    }
    return new HSL(newH % 360, this.s, this.l);
  }

  /**
   * @param power from 0 to 1
   */
  saturate(power: number) {
    let newS = this.s + power * 30
    if (newS > 100) newS = 100;
    if (newS < 0) newS = 0;
    return new HSL(this.h, newS, this.l);
  }

  /**
   * @param power from 0 to 1
   */
  lighten(power: number) {
    let newL = this.l + power * 30
    if (newL > 100) newL = 100;
    if (newL < 0) newL = 0;
    return new HSL(this.h, this.s, newL);
  }
}

export const colorUtils = {

  generateHSL: (id: number): HSL => {
    const num = colorUtils.normalizeNumber(id);

    const ignoreHue = { from: 70, to: 96 };
    const ignoreSize = ignoreHue.to - ignoreHue.from;

    let h = Math.abs(130 + num * .7) % 361 - ignoreSize;
    if (h > ignoreHue.from && h < ignoreHue.to) {
      h += ignoreSize;
    }
    const colorsAmount = 7;
    const h1 = Math.abs(3 + num * .7) % colorsAmount;
    const h2 = Math.abs(11 + num * 3) % (360 / colorsAmount);
    const H = h1 * (360 / colorsAmount) + h2;

    const l = colorUtils.interval(num, 65, 70);
    const s = colorUtils.interval(num, 40, 66) - (l - 65);
    return new HSL(H, s, l);
  },

  interval: (value: number, start: number, end: number): number => {
    return Math.abs(start + ((value % 120) / 120) * (end - start)) % end;
  },

  normalizeNumber: (n: number): number => {
    const m = (n * 16807 + 39123) % 214647;
    return (m * 16807 + 9123) % 214647;
  }
};
